import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from "react-redux";
import ReactPaginate from "react-paginate";
import { productAction, productConstant } from "../../actions/productList.action";
import ProductModal from '../productModal/ProductModal';
import Checkbox from '../../utility/Checkbox.js';
import { SearchInput } from "../../components/SearchInput/SearchInput";
import './product.css';
import '../productModal/Wishmodal.css';
import { Link, useParams, useNavigate, useLocation } from 'react-router-dom';
import { toast } from "react-toastify";
import { Helmet } from "react-helmet";


function ProducstList(props) {
    const dispatch = useDispatch();
    let location = useLocation();
    let navigate = useNavigate();

    const { parentCateg, subCateg, childCateg } = useParams();
    const loading = useSelector((state) => state.product.loading);
    const list = useSelector((state) => state.product.products);
    const brandListing = useSelector((state) => state.product.brandList);
    const colorList = useSelector((state) => state.product.colorList);
    const storeListing = useSelector((state) => state.product.storeList);
    const productDetails = useSelector((state) => state.product.productDetails);
    const productOptionAttrList = useSelector((state) => state.product.productOptionAttrList);
    const auth = useSelector((state) => state.auth);
    const user_id = auth.userDetails?.id ?? null
    
    const favoritesData = useSelector((state) => state.product.favoritesData);
    const favProductUsers = favoritesData?.favProducts?.length ? favoritesData?.favProducts?.map(product => product.user_id) : [];
    // const favProductIds = favoritesData?.favProducts?.length ? favoritesData?.favProducts?.map(product => product.product_id) : [];
    
    const favoriteProductsWithColors = favoritesData?.favProducts?.length
        ? favoritesData.favProducts.map(product => ({
            product_id: product.product_id,
            color_id: product.color_id
            }))
        : [];
    const favoriteProductColorPairs = new Set(
        favoriteProductsWithColors.map(item => `${item.product_id}-${item.color_id}`)
    );
        
    const isFavorite = (product, product_id) => {
        let isPrimary = 0;
        let colorId = 0;
        product?.product_color_data?.map((item, index) => {
            if (item?.is_primary === 1) {
                window.$(`#color-hovered${item?.color_id}`).click();
                isPrimary = item?.is_primary;
                colorId = item?.id;
            }
        });

        if (isPrimary) {
            return (isPrimary && favoriteProductColorPairs.has(`${product_id}-${colorId}`));
        } else {
            return false;
        }
    }

    const categoryFilterUrl = useSelector((state) => state.category?.categoryFilterUrl);   

    // UseStates
    const [page, setPage] = useState(0);
    const [keyword, setKeyword] = useState('');
    const [sort, setSort] = useState('');
    const [filterType, setFilterType] = useState('');
    const [priceSort, setPriceSort] = useState('Newest');
    const [productFilter, setProductFilter] = useState([]);
    const [leftFilter, setLeftFilter] = useState([]);
    const [favorite, setFavorite] = useState({ content: 'ADD TO FAVORITES', wishContent: 'ADD TO ', status: false });
    const [storeListData, setStoreListData] = useState();
    const [storeList, setStoreList] = useState([]);
    const [brandListData, setBrandListData] = useState();
    const [brandList, setBrandList] = useState([]);
    const [colorData, setColorData] = useState();
    const [clickedImage, setClickedImage] = useState({});
    const [favColorId, setFavColorId] = useState(null);
    
    // Filter brands based on product list
    useEffect(() => {
        if (brandListing) {
            const filteredBrands = brandListing?.data?.filter(brand =>
                list?.data?.some(product => product.brand_id === brand.id)
            );
            setBrandList({data: filteredBrands});
        }
    }, [brandListing, list])

    // Filter stores based on product list
    useEffect(() => {
        if (storeListing) {
            const filteredStores = storeListing?.data?.filter(store =>
                list?.data?.some(product => product.store_id === store.id)
            );

            setStoreList({data: filteredStores});
        }
    }, [storeListing, list])

    useEffect(() => {
        document.querySelectorAll('.filter-link').forEach((filter) => {
            filter.classList.remove('show')
        })
    }, [parentCateg, subCateg, childCateg])

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [])
    
    /* Static price range value for filter... */
    const priceRange = [
        { 'id': '1', 'title': 'Under $50', 'value': '0 - 50' },
        { 'id': '2', 'title': '$50 - $100', 'value': '50 - 100' },
        { 'id': '3', 'title': '$100 - $500', 'value': '100 - 500' },
        { 'id': '4', 'title': '$500 - $1000', 'value': '500 - 1000' },
        { 'id': '5', 'title': '$1000 - $5000', 'value': '1000 - 5000' },
    ]

    // Array of alphabets
    const alphabets = ['#', ...Array.from({ length: 26 }, (_, i) => String.fromCharCode(65 + i))];

    // Category filter data here
    if (props.flag !== 'searchKey' && props.flag !== 'homepageProduct') {
        useEffect(() => {
            dispatch({ type: productConstant.GET_PRODUCT_PENDING });
            dispatch(productAction.getProducts({user_id, page: page, keyword: keyword, parentCateg: parentCateg, subCateg: subCateg, childCateg: childCateg }));
            setPage(0)
            handleLeftFilter();
            setLeftFilter([]);
            setPriceSort('Newest')


        }, [categoryFilterUrl, childCateg]);

        useEffect(() => {
            dispatch(
                productAction.getProducts({user_id,
                    page: 1,
                    filter_type: filterType,
                    request_type: "all_products",
                    parentCateg: parentCateg,
                    subCateg: subCateg,
                    childCateg: childCateg
                }),
            );
            setFilterType(filterType);

            setPage(0);
        }, [filterType]);


        useEffect(() => {
            const delayDebounceFn = setTimeout(() => {
                dispatch(productAction.getProducts({user_id, page: page, keyword: keyword, parentCateg: parentCateg, subCateg: subCateg, childCateg: childCateg }));
            }, 300)

            return () => clearTimeout(delayDebounceFn)
        }, [keyword])

    }

    if (props.flag === 'searchKey' && props.homepage === '') {
        // Filter product on search key here...
        useEffect(() => {
            dispatch({ type: productConstant.GET_PRODUCT_PENDING });
            dispatch(productAction.getProducts({user_id, page: page, keyword: keyword, searchKeyword: props?.searchKeyword }));

        }, [props?.searchKeyword])
    }

    if (props.flag === 'searchKey' && props.homepage !== '') {
        // Filter product on search key here...
        useEffect(() => {
            dispatch({ type: productConstant.GET_PRODUCT_PENDING });
            dispatch(productAction.getProducts({user_id, page: page, keyword: keyword, searchKeyword: props.homepage }));

        }, [props.homepage])
    }

    if (props.flag === 'homepageProduct') {
        // Filter product on search key here...
        useEffect(() => {
            dispatch({ type: productConstant.GET_PRODUCT_PENDING });
            dispatch(productAction.getProducts({user_id, page: page, keyword: keyword, homepageKey: props.homepageKey }));

        }, [props.homepageKey]);
    }

    useEffect(() => {
        dispatch(productAction.getFavoritesData({ user_id, Token: auth.userDetails?.token }));
    }, [])

    useEffect(() => {
        /* Getting brands here... */
        dispatch(productAction.getBrands());

        /* Getting stores here... */
        dispatch(productAction.getStores());

        dispatch(productAction.getColors());

        /* Getting product option attributes here... */
        dispatch(productAction.productOptionAttr());
    }, [filterType]);

    useEffect(() => { }, [leftFilter])

    const handlePageChange = (page,) => {
        window.scrollTo(0, 0);
        setPage(page.selected)
        setSort(sort)

        if (props.flag === 'homepageProduct') {
            dispatch({ type: productConstant.GET_PRODUCT_PENDING });
            dispatch(productAction.getProducts({user_id, page: page.selected + 1, keyword: keyword, homepageKey: props.homepageKey }));
        } else {
            dispatch(
                productAction.getProducts({user_id,
                    page: page.selected + 1,
                    keyword: keyword,
                    filter_type: filterType,
                    sort: sort,
                    parentCateg: parentCateg,
                    subCateg: subCateg,
                    childCateg: childCateg,
                    searchKeyword: props?.searchKeyword,
                    store: productFilter?.store,
                    brand: productFilter?.brand,
                    price_range: productFilter?.price_range,
                    color: productFilter?.color,
                    size: productFilter?.size,
                })
            );
        }
    };

    /* Filter Menu IN MOBILE*/
    const handleFilterModal = (e) => {
        const currentDropdown = e.currentTarget.parentNode;

        if (currentDropdown.classList.contains('show')) {
            currentDropdown.classList.remove('show');
        } else {
            document.querySelectorAll('.dropdown').forEach((filter) => filter.classList.remove('show'));
            currentDropdown.classList.add('show');
        }

        document.addEventListener('click', (e) => {
            const click1 = e.target;
            if (!currentDropdown.contains(click1)) {
                currentDropdown.classList.remove('show');
            }
        })

        const storeSearchInput = document.getElementById('storeSearchBox');
        const BrandSearchInput = document.getElementById('brandSearchBox');

        if (storeSearchInput) {
            storeSearchInput.value = '';
            document.getElementById('crossButton').classList.add("d-none");
        }
        if (BrandSearchInput) {
            BrandSearchInput.value = '';
            document.getElementById('crossbrandbutton').classList.add("d-none");
        }
        setStoreListData({ ...storeList });
        setBrandListData({ ...brandList });
        removeAlphabet();

    }

    /* Sorting by price*/
    const handleSortValue = (e, val) => {
        const currentDropdown = e.currentTarget

        document.addEventListener('click', (e) => {
            const click1 = e.target;

            if (!currentDropdown.contains(click1)) {
                currentDropdown.classList.remove('show');
            } else {
                document.querySelectorAll('.dropdown').forEach((filter) => filter.classList.remove('show'));
                currentDropdown.classList.add('show');
            }
        })

        setPriceSort(e.target.text);
        dispatch(
            productAction.getProducts({user_id,
                page: 1,
                sort: val,
                parentCateg: parentCateg,
                subCateg: subCateg,
                childCateg: childCateg,
                store: productFilter?.store,
                brand: productFilter?.brand,
                price_range: productFilter?.price_range,
                color: productFilter?.color,
                size: productFilter?.size,

            }),
        );
        setPage(0);
        setSort(val)
    }

    /* Product filter here... */
    const handleProductFilters = (filter_name) => (e) => {
        e.preventDefault();
        e.stopPropagation();

        let value = e.target.alt;
        let filterId = filter_name + '' + e.target.id;
        let checked = document.getElementById(filterId).checked;
        let currFilter = productFilter;
        let currentFilter = productFilter[filter_name] ? productFilter[filter_name] : [];

        if (!checked) {
            if (!currentFilter.includes(value)) {
                currentFilter.push(value);
            }

            e.target.parentElement.classList.add('image-checkbox-checked');
            document.getElementById(filterId).checked = true;

        } else {
            if (currentFilter.includes(value)) {
                currentFilter = currentFilter.filter(function (item) {
                    return item !== value
                })
            }

            e.target.parentElement.classList.remove('image-checkbox-checked');
            document.getElementById(filterId).checked = false;
        }

        currFilter[filter_name] = currentFilter;
        currFilter['parentCateg'] = parentCateg;
        currFilter['subCateg'] = subCateg;
        currFilter['childCateg'] = childCateg;
        currFilter['sort'] = sort;

        setLeftFilter(currFilter);
        setProductFilter(currFilter);
        
        /* filter by store here... */
        dispatch(productAction.getProductByFilter(currFilter));
    }

    useEffect(() => {
        resetFilterList('storeCheckbox', 'store');
        resetFilterList('brandCheckbox', 'brand');
    }, [storeList, brandList]);

    const resetFilterList = (className, type) => {
        const storeCheckboxEl = document.querySelectorAll('input.' + className);

        storeCheckboxEl.forEach((el) => {
            el.checked = false;
            el.closest('label').classList.remove('image-checkbox-checked')
            if (productFilter[type]?.includes(el.value)) {
                el.checked = true;
                el.closest('label').classList.add('image-checkbox-checked')
            }

        });
    }

    /* Searching store and brand name here... */
    const handleSearchChange = (e, type) => {
        const value = e.target.value.toLowerCase();

        if (type === 'storeList') {
            if (value === '') {
                setStoreListData(storeList);
            } else {
                let data = storeList?.data?.filter((item) => {
                    return item?.store_name.toLowerCase().indexOf(value) !== -1;
                });

                setStoreListData({data});

            }
        } else if (type === 'brandList') {
            if (value === '') {
                setBrandListData(brandList);
            } else {
                let data = brandList?.data?.filter((item) => {
                    return item?.display_name.toLowerCase().indexOf(value) !== -1;
                });

                setBrandListData({data});
            }
        }

        const ids = ['crossButton', 'crossbrandbutton'];

        if (value !== '') {
            ids.forEach((id) => {
                const button = document.getElementById(id);
                if (button) {
                    button.classList.remove("d-none");
                }
            })
        } else {
            ids.forEach((id) => {
                const button = document.getElementById(id);
                if (button) {
                    button.classList.add("d-none");
                }
            })
        }
    };

    /*Filter searchbox cross button work here..*/
    const handleFilterSearchBox = async (e, id, type) => {
        e.preventDefault();

        document.getElementById('crossButton').classList.add("d-none")
        document.getElementById('crossbrandbutton').classList.add("d-none")
        document.getElementById(id).value = '';
        document.getElementById(id).focus();

        if (type === 'storeList') {
            setStoreListData(storeList);
        } else if (type === 'brandList') {
            setBrandListData(brandList);
        }

    }
    /*Leftsidefilter clearall work here... */
    const handleLeftFilter = () => {
        let filterClasses = ['storeCheckbox', 'brandCheckbox', 'colorCheckbox', 'sizeCheckbox', 'priceCheckbox']
        for (let i = 0; i < filterClasses.length; i++) {
            handleClearAll(filterClasses[i])
        }
        setLeftFilter([])
    }

    /*Leftside single single item deleted work here */
    const handleDeleteFilter = (filterValue, filterType) => {
        const updatedFilter = {
            ...leftFilter,
            sort: sort,
        };
        const updatedFilter1 = {
            ...productFilter,
            sort: sort,
        };

        if (filterType === 'storeCheckbox') {
            var store = document.getElementsByClassName("storeCheckbox");
            updatedFilter.store = updatedFilter?.store?.filter((name) => name !== filterValue);
            updatedFilter1.store = updatedFilter1?.store?.filter((name) => name !== filterValue);

            for (var i = 0; i < store.length; i++) {
                if (filterValue === store[i].value || filterValue === store[i].alt) {
                    store[i].checked = false;
                    store[i].parentElement.classList.remove('image-checkbox-checked');
                }
            }
        } else if (filterType === 'brandCheckbox') {
            var brand = document.getElementsByClassName("brandCheckbox");
            updatedFilter.brand = updatedFilter?.brand?.filter((name) => name !== filterValue);
            updatedFilter1.brand = updatedFilter1?.brand?.filter((name) => name !== filterValue);

            for (var i = 0; i < brand.length; i++) {
                if (filterValue === brand[i].value || filterValue === brand[i].alt) {
                    brand[i].checked = false;
                    brand[i].parentElement.classList.remove('image-checkbox-checked');
                }
            }
        }
        else if (filterType === 'colorCheckbox') {
            var color = document.getElementsByClassName("colorCheckbox");
            updatedFilter.color = updatedFilter?.color?.filter((name) => name !== filterValue);
            updatedFilter1.color = updatedFilter1?.color?.filter((name) => name !== filterValue);

            for (var i = 0; i < color.length; i++) {
                if (filterValue === color[i].value || filterValue === color[i].alt) {
                    color[i].checked = false;
                    color[i].parentElement.classList.remove('image-checkbox-checked');
                }
            }
        }
        else if (filterType === 'sizeCheckbox') {
            var size = document.getElementsByClassName("sizeCheckbox");
            updatedFilter.size = updatedFilter?.size?.filter((name) => name !== filterValue);
            updatedFilter1.size = updatedFilter1?.size?.filter((name) => name !== filterValue);

            for (var i = 0; i < size.length; i++) {
                if (filterValue === size[i].value || filterValue === size[i].alt) {
                    size[i].checked = false;
                    size[i].parentElement.classList.remove('image-checkbox-checked');
                }
            }
        }
        else if (filterType === 'priceCheckbox') {
            var price = document.getElementsByClassName("priceCheckbox");
            updatedFilter.price_range = updatedFilter?.price_range?.filter((name) => name !== filterValue);
            updatedFilter1.price_range = updatedFilter1?.price_range?.filter((name) => name !== filterValue);

            for (var i = 0; i < price.length; i++) {
                if (filterValue === price[i].value || filterValue === price[i].alt) {
                    price[i].checked = false;
                    price[i].parentElement.classList.remove('image-checkbox-checked');
                }
            }
        }

        setLeftFilter(updatedFilter);
        setProductFilter(updatedFilter1);

        dispatch(productAction.getProductByFilter(updatedFilter));
    }

    /*Filter clearall work here */
    const handleClearAll = (classParam) => {
        const attr = document.getElementsByClassName(classParam);

        for (let i = 0; i <= attr.length; i++) {
            if (!attr[i]) { continue; }
            if (attr[i].getAttribute('src')) {
                attr[i].parentElement.classList.remove('image-checkbox-checked');

            } else {
                attr[i].checked = false;
            }
        }

        /* Getting all products here... */
        dispatch(productAction.getProducts({user_id, page: 1, parentCateg: parentCateg, subCateg: subCateg, childCateg: childCateg, searchKeyword: props?.searchKeyword }));
        setProductFilter([]);

        if (classParam === 'storeCheckbox') {
            const updatedFilter = {
                ...leftFilter,
                store: [],
            };
            setLeftFilter(updatedFilter);
            // setStoreListData({ ...storeList });
            removeAlphabet();
        }
        if (classParam === 'brandCheckbox') {
            const updatedFilter = {
                ...leftFilter,
                brand: [],
            };
            setLeftFilter(updatedFilter);
            // setBrandListData({ ...brandList });
            removeAlphabet();
        }
        if (classParam === 'colorCheckbox') {
            const updatedFilter = {
                ...leftFilter,
                color: [],
            };
            setLeftFilter(updatedFilter);
        }
        if (classParam === 'sizeCheckbox') {
            const updatedFilter = {
                ...leftFilter,
                size: [],
            };
            setLeftFilter(updatedFilter);
        }
        if (classParam === 'priceCheckbox') {
            const updatedFilter = {
                ...leftFilter,
                price_range: [],
            };
            setLeftFilter(updatedFilter);
        }

    };

    // Product Details modal 
    const [show, setShow] = useState(false);
    const [productId, setProductId] = useState('');

    const handleClose = () => {
        setShow(false);
        setProductId('');
        dispatch(productAction.getFavoritesData({ user_id, Token: auth.userDetails?.token }));
    };
    const handleShow = (prodId) => {
        dispatch(productAction.getProductById(prodId));
        setShow(true);
        setProductId(prodId);
        /* Checking product id exsits or not in favorites data */
        if (favoritesData?.favProducts?.length) {
            for (let i = 0; i < favoritesData?.favProducts?.length; i++) {
                if (favoritesData?.favProducts[i]?.product_id === prodId) {
                    setFavorite({ content: 'REMOVE FROM FAVORITES', status: true });
                    break;
                } else {
                    setFavorite({ content: 'ADD TO FAVORITES', status: false });
                }
            }
        }
    };

    // Handling display fav product on the basis of color
    const isFavProductExists = (productId, colorId) => {
        return favoritesData?.favProducts?.filter((item) => item.color_id === Number(colorId) && item.product_id === productId);
    }

    /* Add to favorite */
    const handleFavorite = (pId, e) => {
        e.preventDefault();
        let uid = auth.userDetails?.id;
        // let type = favorite.status? 'remove': 'add';
        let type = 'add';

        const parent = document.querySelector('#favIcon-link' + pId + ' i');
        const colorId = window.$(`#colorListing${pId} option:selected`).val();
        const parentColorId = window.$(`#colorListing${pId} option:selected`).data('parent');

        // Checking is fav product exists
        const isExists = isFavProductExists(pId, colorId);

        if (!auth.auth) {
            toast.error('Login Required!');
            navigate('/login', { state: { from: location } });
            return false;
        }
        
        if (!isExists?.length) {
            setFavorite({ content: 'REMOVE FROM FAVORITES', wishContent: 'REMOVE FROM ', status: true })

            if (parent) {
                parent.classList.remove('fa-heart-o');
                parent.classList.add('fa-heart');
            }
        } else {
            setFavorite({ content: 'ADD TO FAVORITES', wishContent: 'ADD TO ', status: false })
            type = 'remove';

            if (parent) {
                parent.classList.add('fa-heart-o');
                parent.classList.remove('fa-heart');
            }
        }

        dispatch(productAction.addToFavorite({ product_id: pId, color_id: colorId, parent_color_id: parentColorId, user_id: uid, actionType: type }, { Token: auth.userDetails?.token }));

    }

    // Wish Modal
    const handleWishModalClose = (prodId) => {
        dispatch(productAction.getFavoritesData({ user_id, Token: auth.userDetails?.token }));
        document.getElementById("myWishModal" + prodId).style.display = 'none';
    };
    
    const handleWishModalShow = (prodId) => {
        dispatch(productAction.getFavoritesData({ user_id, Token: auth.userDetails?.token }));
        let elements = document.getElementsByClassName("whis-modal")

        Array.from(elements).forEach((el) => {
            if (el.id === "myWishModal" + prodId) {
                el.style.display = 'block'
            } else {
                el.style.display = 'none'
            }
        });
        
        /* Checking product id exsits or not in favorites data */
        const colorId = window.$(`#colorListing${prodId} option:selected`).val();
        const isExists = isFavProductExists(prodId, colorId);

        if (isExists?.length) {
            setFavorite({ wishContent: 'REMOVE FROM ', status: true });
        } else {
            setFavorite({ wishContent: 'ADD TO ', status: false });
        }

    }

    const handleAlphabetFilter = (e, letter, type) => {
        const values = letter.toLowerCase();

        removeAlphabet();
        e.target.classList.add('searchAlpha-dark');

        if (type === 'storeList') {
            if (values === '#') {
                setStoreListData(storeList);
            } else {
                let data = storeList?.data?.filter((item) => item.store_name.toLowerCase().startsWith(values));
                setStoreListData({data});
            }
        } else if (type === 'brandList') {
            if (values === '#') {
                setBrandListData(brandList);
            } else {
                let data = brandList?.data?.filter((item) => item.display_name.toLowerCase().startsWith(values));
                setBrandListData({data});
            }
        }
    }

    const removeAlphabet = () => {
        const alphabet = document.querySelectorAll('.searchAlpha-dark');
        alphabet.forEach((element) => {
            element.classList.remove('searchAlpha-dark');
        });
    };

    // Handle color lable events here...
    const handleColorHover = (e, colorIndex, productId, productImage, baseUrl, eventType = '', isColorSelected = null) => {
        const imgSrc = document.getElementById(`productImage_${productId}`);

        if (imgSrc?.src && productImage) {
            if (clickedImage[productId] && eventType !== 'out') {
                var colorImages = JSON.parse(productImage);
                imgSrc.setAttribute('src', baseUrl + '' + colorImages[0]);
            } else {
                var colorImages = JSON.parse(productImage);

                if (clickedImage[productId]) {
                    imgSrc.setAttribute('src', clickedImage[productId]);
                } else {
                    if ('color' in productFilter && Array.isArray(productFilter.color) && productFilter?.color?.length > 0) {
                        if (isColorSelected) {
                            imgSrc.setAttribute('src', isColorSelected);
                            return;
                        }
                    }
                    
                    imgSrc.setAttribute('src', baseUrl + '' + colorImages[0]);
                }

            }
        }
    }

    const handleAddToFavorite = (e, colorIndex, productId, productImage, baseUrl, colorId) => {
        const imgSrc = document.getElementById(`productImage_${productId}`);

        if (imgSrc?.src && productImage) {
            var colorImages = JSON.parse(productImage);
            setClickedImage(prevState => ({
                ...prevState,
                [productId]: baseUrl + '' + colorImages[0]
            }));

            document.querySelectorAll(`.colorLable${productId} div`).forEach((element) => {
                element.style.border = '';
            })

            e.target.style.border = '2px solid #271717';

            // Handling display fav product on the basis of color
            setFavColorId(colorId);
            const isExists = isFavProductExists(productId, colorId);
            const parentFavDiv = document.querySelector('#favIcon-link' + productId + ' i');
            
            
            if (isExists?.length) {
                if (parentFavDiv) {
                    parentFavDiv.classList?.remove('fa-heart-o');
                    parentFavDiv.classList?.add('fa-heart');
                }
            } else {
                if (parentFavDiv) {
                    parentFavDiv.classList?.remove('fa-heart');
                    parentFavDiv.classList?.add('fa-heart-o');
                }
            }
        }
    }

    // Filter colors based on product list
    useEffect(() => {
        // Extract parent color IDs from product list
        const parentColorIds = list?.data?.reduce((acc, product) => {
            product.product_color_data.forEach(colorData => {
                acc.add(colorData.parent_color_id);
            });

            return acc;
        }, new Set());
    
        // Filter colors based on parent color IDs
        const filteredColors = colorList?.filter(color => parentColorIds?.has(color.id));
        setColorData(filteredColors);
      }, [colorList, loading]);

    let breadcrumbCateg = childCateg?.replace(/[0-9-]/g, '');

    return (
        <div>
            <Helmet>
                <title>Shop amazing cloths for men,women,and kids</title>
                <meta name="description" content="Explore our wide range of clothing at shopthumb. Find the best deals on cloths with fast shipping and excellent customer service." />
                <meta name="keywords" content="clothing, apparel, fashion, trendy clothes, men's clothing, women's clothing, kids' clothing, online shopping, fashion store, buy clothes online" />
            </Helmet>
            {/* breadcrumb */}
            <section id="inner-headline" className="mx-3">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-md-12 col-xs-12 text-center text-xs-center">
                            {parentCateg !== undefined ?
                                <ul className="breadcrumb">
                                    <li>{parentCateg.charAt(0).toUpperCase() + parentCateg.slice(1)}</li>
                                    <li className="">{subCateg.charAt(0).toUpperCase() + subCateg.slice(1)}</li>

                                    <li className="active">{breadcrumbCateg.charAt(0).toUpperCase() + breadcrumbCateg.slice(1)}</li>
                                </ul>
                                : <ul className="breadcrumb"></ul>}
                        </div>
                    </div>
                </div>
            </section>
            <section id="body_content" className="mx-3">
                <div className="container-fluid">
                    {/* filter  */}
                    <div className="row">
                        <div className="col-lg-12 filter-main">
                            <div className="mo-filter-sort">
                                <a href="javascript:void(0);" className="mfs" onClick="mobFilter()"> Filter </a>
                            </div>
                            {(props.flag !== 'searchKey') ?
                                <>
                                    <div className="filter-nav mb-4">
                                        <div className=" navbar-collapse" id="myFilter">
                                            <div className="mobile-filter-close">
                                                <a href="javascript:void(0);" className="mfc" onClick="mobFilterclose()"> X </a>
                                            </div>
                                            <ul className="filter nav navbar-nav">
                                                <h6 className="simple_label hide-mob mr-3">
                                                    <p>Filter</p>
                                                </h6>
                                                <li className="dropdown filter-link">
                                                    <a data-toggle="dropdown" href="javascript:void(0);" onClick={(e) => handleFilterModal(e)}>Store</a>
                                                    <div className="dropdown-menu " data-hover="dropdown ">
                                                        <div className="fx-ht">
                                                            <div className="filter-title sbox">
                                                                <span className="clearable b_search mr-4">
                                                                    <SearchInput
                                                                        id="storeSearchBox"
                                                                        placeholder="Search For Store"
                                                                        className="form-control form-control--search mx-auto"
                                                                        onChange={(e) => handleSearchChange(e, 'storeList')}
                                                                    />
                                                                    <div className="clearable__clear" id='crossButton' onClick={(e) => handleFilterSearchBox(e, 'storeSearchBox', 'storeList')}>
                                                                        <svg
                                                                            fill="#c9c9cc9"
                                                                            className="close-icons"
                                                                            xmlns="http://www.w3.org/2000/svg"
                                                                            viewBox="0 0 16 16"
                                                                            width="16px"
                                                                            height="16px"
                                                                        >
                                                                            <path d="M 2.75 2.042969 L 2.042969 2.75 L 2.398438 3.101563 L 7.292969 8 L 2.042969 13.25 L 2.75 13.957031 L 8 8.707031 L 12.894531 13.605469 L 13.25 13.957031 L 13.957031 13.25 L 13.605469 12.894531 L 8.707031 8 L 13.957031 2.75 L 13.25 2.042969 L 8 7.292969 L 3.101563 2.398438 Z" />
                                                                        </svg>
                                                                    </div>
                                                                </span>
                                                                <ul className="searchAlpha">
                                                                    {alphabets?.length > 0 && alphabets.map((alphabet, index) => (
                                                                        <li key={index} onClick={(e) => handleAlphabetFilter(e, alphabet, 'storeList')}>
                                                                            {alphabet}
                                                                        </li>
                                                                    ))}
                                                                </ul>
                                                            </div>
                                                            <ul className="horizontal_row checkbx">
                                                                {storeListData?.data?.length > 0 ? storeListData?.data.map((store, index) => {
                                                                    return (
                                                                        <li className="term-item">
                                                                            <label className={`image-checkbox ${(productFilter?.store?.includes(store.store_name) && 'image-checkbox-checked')}`} id={`storechecked${store.id}`} htmlFor={`str${index + 1}`} >
                                                                                <img loading="lazy" src='/images/size-border.png' id={store.id} alt={store.store_name} className="img-responsive storeCheckbox" onClick={handleProductFilters('store')} name="storeImages" />
                                                                                <Checkbox
                                                                                    id={`store${store.id}`}
                                                                                    name={`store_id`}
                                                                                    value={store.store_name}
                                                                                    className='storeCheckbox d-none '
                                                                                    defaultChecked={(productFilter?.store?.includes(store.store_name)) ? true : false}
                                                                                />
                                                                                <div className="filter-options" id={`str${index + 1}`}>{store?.store_name.replace(/`/g, '\'')}</div>
                                                                            </label>
                                                                        </li>
                                                                    )
                                                                }) :
                                                                    <tr className="text-center no-record-found"><td>No Store Found!</td></tr>
                                                                }
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </li>
                                                <li className="dropdown filter-link">
                                                    <a data-toggle="dropdown" href="javascript:void(0);" onClick={(e) => handleFilterModal(e)}>Brand</a>
                                                    <div className="dropdown-menu" data-hover="dropdown">
                                                        <div className="fx-ht">
                                                            <div className="filter-title sbox">
                                                                <span className="clearable b_search">
                                                                    {/* <input type="text" placeholder="Search For Brand" /> */}
                                                                    <SearchInput
                                                                        id="brandSearchBox"
                                                                        placeholder="Search For Brand"
                                                                        className="form-control form-control--search mx-auto"
                                                                        onChange={(e) => handleSearchChange(e, 'brandList')}
                                                                    />
                                                                    <div className="clearable__clear d-none" id='crossbrandbutton' onClick={(e) => handleFilterSearchBox(e, 'brandSearchBox', 'brandList')}>
                                                                        <svg
                                                                            fill="#c9c9cc9"
                                                                            className="close-icons"
                                                                            xmlns="http://www.w3.org/2000/svg"
                                                                            viewBox="0 0 16 16"
                                                                            width="16px"
                                                                            height="16px"
                                                                        >
                                                                            <path d="M 2.75 2.042969 L 2.042969 2.75 L 2.398438 3.101563 L 7.292969 8 L 2.042969 13.25 L 2.75 13.957031 L 8 8.707031 L 12.894531 13.605469 L 13.25 13.957031 L 13.957031 13.25 L 13.605469 12.894531 L 8.707031 8 L 13.957031 2.75 L 13.25 2.042969 L 8 7.292969 L 3.101563 2.398438 Z" />
                                                                        </svg>
                                                                    </div>
                                                                </span>
                                                                <ul className="searchAlpha ml-4">
                                                                    {alphabets?.length > 0 && alphabets.map((alphabet, index) => (
                                                                        <li key={index} onClick={(e) => handleAlphabetFilter(e, alphabet, 'brandList')}>
                                                                            {alphabet}
                                                                        </li>
                                                                    ))}
                                                                </ul>
                                                            </div>
                                                            <ul className="horizontal_row checkbx">
                                                                {brandListData?.data?.length > 0 ? brandListData.data.map((brand, index) => {
                                                                    return (
                                                                        <li className="term-item">
                                                                            <label className={`image-checkbox ${(productFilter?.brand?.includes(brand.display_name) && 'image-checkbox-checked')}`} htmlFor={`brd${index + 1}`}>
                                                                                <img loading="lazy" name='brand_id' src="/images/size-border.png" id={brand.id} alt={brand.display_name} className="img-responsive brandCheckbox" onClick={handleProductFilters('brand')} />
                                                                                <Checkbox
                                                                                    id={`brand${brand.id}`}
                                                                                    name='brand_id'
                                                                                    value={brand.display_name}
                                                                                    className='brandCheckbox d-none'
                                                                                    defaultChecked={(productFilter?.brand?.includes(brand.display_name)) ? true : false}
                                                                                />

                                                                                <div className="filter-options" >{brand?.display_name.replace(/`/g, '\'')}</div>
                                                                            </label>
                                                                        </li>
                                                                    )
                                                                }) :
                                                                    <tr className="text-center no-record-found"><td>No Brand Found!</td></tr>
                                                                }
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </li>
                                                <li className="dropdown filter-link">
                                                    <a data-toggle="dropdown" href="javascript:void(0);" onClick={(e) => handleFilterModal(e)}>Color</a>
                                                    <div className="dropdown-menu" data-hover="dropdown">
                                                        <div className="fx-ht">
                                                            <ul className="horizontal_row checkbx color">
                                                                {colorData?.length > 0 ? colorData?.map((color, index) => {
                                                                    return (
                                                                        <li className="term-item">
                                                                            <label className="image-checkbox" htmlFor={`clr${index + 1}`}>
                                                                                <img loading="lazy" src="/images/size-border.png" id={color.id} alt={color.color_name} className="img-responsive colorCheckbox" onClick={handleProductFilters('color')} />

                                                                                <Checkbox
                                                                                    id={`color${color.id}`}
                                                                                    name={`color_id`}
                                                                                    value={color.color_name}
                                                                                    className='colorCheckbox d-none'
                                                                                />
                                                                                <div className="filter-options" >{color?.color_name}</div>
                                                                            </label>
                                                                        </li>
                                                                    )
                                                                }) :
                                                                    <tr className="text-center no-record-found"><td>No Color Found!</td></tr>
                                                                }
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </li>
                                                <li className="dropdown filter-link">
                                                    <a data-toggle="dropdown" href="javascript:void(0);" onClick={(e) => handleFilterModal(e)}>Size</a>
                                                    <div className="dropdown-menu" data-hover="dropdown">
                                                        <div className="fx-ht">
                                                            <ul className="horizontal_row checkbx checkbx">
                                                                {productOptionAttrList?.data?.size?.length > 0 ? productOptionAttrList?.data?.size.map((attr, index) => {
                                                                    return (
                                                                        <li className="term-item">
                                                                            <label className="image-checkbox" htmlFor={`clr${index + 1}`}>
                                                                                <img loading="lazy" src="/images/size-border.png" id={attr.id} alt={attr.attribute_value} className="img-responsive sizeCheckbox" onClick={handleProductFilters('size')} />

                                                                                <Checkbox
                                                                                    id={`size${attr.id}`}
                                                                                    name={`size_id`}
                                                                                    value={attr.attribute_name}
                                                                                    className='sizeCheckbox d-none'
                                                                                />

                                                                                <div className="filter-options" >{attr?.attribute_value}</div>
                                                                            </label>
                                                                        </li>
                                                                    )
                                                                }) :
                                                                    <tr className="text-center no-record-found"><td>No Size Found!</td></tr>
                                                                }
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </li>
                                                <li className="dropdown filter-link">
                                                    <a data-toggle="dropdown" href="javascript:void(0);" onClick={(e) => handleFilterModal(e)}>Price</a>
                                                    <div className="dropdown-menu" data-hover="dropdown">
                                                        <div className="fx-ht">
                                                            <ul className="vertical_clm checkbx">
                                                                {priceRange.length > 0 ? priceRange.map((price, index) => {
                                                                    return (
                                                                        <li className="term-item">
                                                                            <label className="image-checkbox" htmlFor={`str${index + 1}`}>
                                                                                <img loading="lazy" src='/images/size-border.png' alt={price.value} id={price.id} className="img-responsive priceCheckbox" onClick={handleProductFilters('price_range')} />

                                                                                <Checkbox
                                                                                    id={`price_range${price.id}`}
                                                                                    name='price_id'
                                                                                    value={price.value}
                                                                                    className='priceCheckbox d-none'
                                                                                />

                                                                                <div className="filter-options" >{price.title}</div>
                                                                            </label>
                                                                        </li>
                                                                    )
                                                                }) :
                                                                    <tr className="text-center no-record-found"><td>No price Found!</td></tr>
                                                                }

                                                            </ul>
                                                        </div>
                                                    </div>
                                                </li>
                                            </ul>

                                        </div>
                                    </div>
                                    <div className="sort-nav">
                                        <div className="collapse1 navbar-collapse" id="mySort">
                                            <div className="mobile-filter-close hide-mob">
                                                <a href="javascript:void(0);" className="mfc" onClick="mobSortclose()"> X </a>
                                            </div>
                                            <ul className="nav navbar-nav">

                                                <h6 className="simple_label hide-mob mr-1">
                                                    <p>Sort</p>
                                                </h6>
                                                {/* <!-- <p>Sort</p> --> */}

                                                <li className="dropdown">
                                                    <a className="hide-mob1" data-toggle="dropdown" href="javascript:void(0);" onClick={(e) => handleFilterModal(e)}>{priceSort}<i className="fa fa-caret-down sort-fil-arw" aria-hidden="true"></i></a>
                                                    <div className="dropdown-menu p-0" data-hover="dropdown" id="mobSort_dd">
                                                        <ul className="sort-ul">
                                                            <li className="term-item" onClick={(e) => handleSortValue(e, '')}>
                                                                <a href="javascript:void(0);">Newest </a>
                                                            </li>
                                                            <li className="term-item" onClick={(e) => handleSortValue(e, 'desc')}>
                                                                <a href="javascript:void(0);"> Price high to low </a>
                                                            </li>
                                                            <li className="term-item" onClick={(e) => handleSortValue(e, 'asc')}>
                                                                <a href="javascript:void(0);">Price low to high </a>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </>
                                :
                                <div className='filter-nav mb-4'>
                                    {(props.homepage === '') ?
                                        <p>Search Result for: "{props.searchKeyword}"</p>
                                        : ''}
                                </div>
                            }
                        </div>
                    </div>

                    <div id="main-content" className="row main">
                        <div className="col-lg-2 col-md-2 sidebar d-md-block d-none" id="sidebar">
                            {/* <filterMenu></filterMenu> */}
                            <aside className="left-sidebar sidebar__inner hidden-sm  hidden-xs">
                                <div className="widget">
                                    <div className="filter_tag">
                                        {(leftFilter?.store?.length > 0 || leftFilter?.brand?.length > 0 || leftFilter?.color?.length > 0 || leftFilter?.size?.length > 0 || leftFilter?.price_range?.length > 0) ? <div> <p className="float-left mb-4">Filtered By:</p>
                                            <p className="clear-sel-filter float-right">
                                                <a href="javascript:void(0);" onClick={() => handleLeftFilter()}>CLEAR ALL</a>
                                            </p> </div> : ''}
                                        <div>
                                            <ul>
                                                <div className="clearable__clear ">
                                                    {leftFilter?.store?.length > 0 && leftFilter?.store.map((storeName, i) => (

                                                        <li key={i}>
                                                            <a href="javascript:void(0);" onClick={() => handleDeleteFilter(storeName, 'storeCheckbox')}>{storeName}
                                                                <p>
                                                                    <svg fill="#c9c9cc9" className="close-icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" width="8px" height="8px" ><path d="M 2.75 2.042969 L 2.042969 2.75 L 2.398438 3.101563 L 7.292969 8 L 2.042969 13.25 L 2.75 13.957031 L 8 8.707031 L 12.894531 13.605469 L 13.25 13.957031 L 13.957031 13.25 L 13.605469 12.894531 L 8.707031 8 L 13.957031 2.75 L 13.25 2.042969 L 8 7.292969 L 3.101563 2.398438 Z" /></svg>

                                                                </p>
                                                            </a>
                                                        </li>
                                                    ))}
                                                    {leftFilter?.brand?.length > 0 && leftFilter?.brand.map((brandName, i) => (
                                                        <li key={i}>
                                                            <a href="javascript:void(0);" onClick={() => handleDeleteFilter(brandName, 'brandCheckbox')}>{brandName}
                                                                <p>
                                                                    <svg fill="#c9c9cc9" className="close-icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" width="8px" height="8px" ><path d="M 2.75 2.042969 L 2.042969 2.75 L 2.398438 3.101563 L 7.292969 8 L 2.042969 13.25 L 2.75 13.957031 L 8 8.707031 L 12.894531 13.605469 L 13.25 13.957031 L 13.957031 13.25 L 13.605469 12.894531 L 8.707031 8 L 13.957031 2.75 L 13.25 2.042969 L 8 7.292969 L 3.101563 2.398438 Z" /></svg>
                                                                </p>
                                                            </a>
                                                        </li>
                                                    ))}
                                                    {leftFilter?.color?.length > 0 && leftFilter?.color.map((colorName, i) => (
                                                        <li key={i}>
                                                            <a href="javascript:void(0);" onClick={() => handleDeleteFilter(colorName, 'colorCheckbox')}>{colorName}
                                                                <p>
                                                                    <svg fill="#c9c9cc9" className="close-icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" width="8px" height="8px"><path d="M 2.75 2.042969 L 2.042969 2.75 L 2.398438 3.101563 L 7.292969 8 L 2.042969 13.25 L 2.75 13.957031 L 8 8.707031 L 12.894531 13.605469 L 13.25 13.957031 L 13.957031 13.25 L 13.605469 12.894531 L 8.707031 8 L 13.957031 2.75 L 13.25 2.042969 L 8 7.292969 L 3.101563 2.398438 Z" /></svg>
                                                                </p>
                                                            </a>
                                                        </li>
                                                    ))}
                                                    {leftFilter?.size?.length > 0 && leftFilter?.size.map((sizeName, i) => (
                                                        <li key={i}>
                                                            <a href="javascript:void(0);" onClick={() => handleDeleteFilter(sizeName, 'sizeCheckbox')}>{sizeName}
                                                                <p>
                                                                    <svg fill="#c9c9cc9" className="close-icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" width="8px" height="8px" ><path d="M 2.75 2.042969 L 2.042969 2.75 L 2.398438 3.101563 L 7.292969 8 L 2.042969 13.25 L 2.75 13.957031 L 8 8.707031 L 12.894531 13.605469 L 13.25 13.957031 L 13.957031 13.25 L 13.605469 12.894531 L 8.707031 8 L 13.957031 2.75 L 13.25 2.042969 L 8 7.292969 L 3.101563 2.398438 Z" /></svg>
                                                                </p>
                                                            </a>
                                                        </li>
                                                    ))}
                                                    {leftFilter?.price_range?.length > 0 && leftFilter?.price_range.map((priceName, i) => (
                                                        <li key={i}>
                                                            <a href="javascript:void(0);" onClick={() => handleDeleteFilter(priceName, 'priceCheckbox')}>{"$" + priceName?.replace(/\s/g, '')?.split('-')?.join(' - $')}
                                                                <p>
                                                                    <svg fill="#c9c9cc9" className="close-icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" width="8px" height="8px" ><path d="M 2.75 2.042969 L 2.042969 2.75 L 2.398438 3.101563 L 7.292969 8 L 2.042969 13.25 L 2.75 13.957031 L 8 8.707031 L 12.894531 13.605469 L 13.25 13.957031 L 13.957031 13.25 L 13.605469 12.894531 L 8.707031 8 L 13.957031 2.75 L 13.25 2.042969 L 8 7.292969 L 3.101563 2.398438 Z" /></svg>
                                                                </p>
                                                            </a>
                                                        </li>
                                                    ))}
                                                </div>
                                            </ul>

                                        </div>
                                    </div>
                                    <ul className="cat">
                                        <li><a href="javascript:void(0);" className="active">{childCateg !== undefined ? childCateg.charAt(0).toUpperCase() + childCateg.slice(1) : ''}</a></li>
                                        <li><a href="javascript:void(0);"> Bodysuits</a></li>
                                        <li><a href="javascript:void(0);">Button Down Shirts</a></li>
                                        <li><a href="javascript:void(0);">Camis</a></li>
                                        <li><a href="javascript:void(0);">Crop Tops</a></li>
                                        <li><a href="javascript:void(0);">Graphic Tees</a></li>
                                        <li><a href="javascript:void(0);">Night Out</a></li>
                                        <li><a href="javascript:void(0);">Off the Shoulder Tops</a></li>
                                        <li><a href="javascript:void(0);">Sweatshirts / Hoodies</a></li>
                                        <li><a href="javascript:void(0);">Tank Tops</a></li>
                                        <li><a href="javascript:void(0);">Short Sleeve Tees</a></li>
                                        <li><a href="javascript:void(0);">Long Sleeve Tees</a></li>
                                        <li><a href="javascript:void(0);">Tunics</a></li>
                                        <li><a href="javascript:void(0);">Turtlenecks</a></li>
                                        <li><a href="javascript:void(0);">1.Off the Shoulder Tops</a></li>
                                        <li><a href="javascript:void(0);">Sweatshirts / Hoodies</a></li>
                                        <li><a href="javascript:void(0);">Tank Tops</a></li>
                                        <li><a href="javascript:void(0);">Short Sleeve Tees</a></li>
                                        <li><a href="javascript:void(0);">Long Sleeve Tees</a></li>
                                        <li><a href="javascript:void(0);">Tunics</a></li>
                                        <li><a href="javascript:void(0);">Turtlenecks</a></li>
                                    </ul>
                                </div>
                            </aside>
                        </div>
                        <div className="col-lg-10 col-md-10 content" id="content">
                            <div className="container-fluid">
                                {/* top pagination  */}
                                <div className="row">
                                    <div className="col-md-5"></div>
                                    <div className="col-md-3">
                                        {/* <ul className="pageview">
                                        <li style={{"padding-top": "5px"}}>
                                            &nbsp; Page
                                        </li>
                                        <li>
                                            <input type="text" className="no-of-page" name="no-of-page" maxlength="4" value="1" />
                                        </li>
                                    </ul> */}
                                    </div>
                                    <div className="col-md-4 d-flex justify-content-end">
                                        {list?.total_pages > 1 && (
                                            <div className="sa-datatables__pagination">
                                                <div
                                                    className="dataTables_paginate paging_simple_numbers"
                                                    id="DataTables_Table_0_paginate"
                                                >
                                                    <ReactPaginate
                                                        forcePage={page}
                                                        previousLabel={"previous"}
                                                        nextLabel={"next"}
                                                        breakLabel={"..."}
                                                        breakClassName={"break-me"}
                                                        pageCount={list.total_pages}
                                                        marginPagesDisplayed={2}
                                                        pageRangeDisplayed={2}
                                                        onPageChange={handlePageChange}
                                                        containerClassName={"pagination pagination-sm"}
                                                        activeClassName={"active"}
                                                        pageLinkClassName={"page-link"}
                                                        pageClassName={"paginate_button page-item"}
                                                        previousClassName={"paginate_button page-item previous"}
                                                        previousLinkClassName={"page-link"}
                                                        nextClassName={"paginate_button page-item previous"}
                                                        nextLinkClassName={"page-link"}
                                                    />
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                </div>

                                {/* product list  */}
                                {loading && (
                                    <div>
                                        <div className="loader spinner-border m-5 d-table m-auto" role="status">
                                            <span className="visually-hidden"></span>
                                        </div>
                                        <span className=' m-5 d-table m-auto'>Loading...</span>
                                    </div>

                                )}

                                <div className="row product-main">
                                    {loading !== true && list?.data?.length > 0 ? list?.data?.map((product, index) => {
                                        if (product?.is_active) {
                                            return (
                                                <div className="col-lg-3 col-md-4 col-6 text-center ">
                                                    <div className="product-cnt ">
                                                        <div className="post-image">

                                                            <div className="post-heading">
                                                                <small className="prod-cashbck">{product?.store_shipping_notes}</small>
                                                                <small className="stor_name">{product.store_name}</small>
                                                            </div>

                                                            <span className="mb">
                                                                <div className='productImageContainer'>
                                                                    <a className="ml" target="_blank" href={product?.product_url}>
                                                                        <img
                                                                            id={`productImage_${product.product_id}`}
                                                                            loading="lazy"
                                                                            src={(product.vendor_id == 1) ? product?.primary_image : product?.image_path}
                                                                            className="img-fluid"
                                                                            data-thumbs={`${product.image_name}`} org-image={`${process.env.REACT_APP_API_URL}/${product.image_path}${product.image_name}`}
                                                                        />
                                                                    </a>
                                                                </div>
                                                                <a className="quick_view" href="javascript:void(0);" title="Quick View" data-toggle="modal" data-target="#quick-view-modal" onClick={() => handleShow(product?.product_id)}>
                                                                    <i className="fa fa-eye" aria-hidden="true"></i>
                                                                </a>
                                                            </span>

                                                            <div style={{ display: 'flex' }} className={`colorLable${product?.product_id}`}>
                                                                {product?.product_color_data?.length > 1 && product?.product_color_data?.map((color) => {
                                                                    return (
                                                                        <div
                                                                            key={color.id}
                                                                            id={`color-hovered${color.id}`}
                                                                            data-isprimary={`${color?.is_primary}`}
                                                                            className='color-hovered'
                                                                            style={{
                                                                                width: '25px',
                                                                                height: '25px',
                                                                                borderRadius: '50%',
                                                                                backgroundColor: color?.colors?.hexa,
                                                                                display: 'flex',
                                                                                justifyContent: 'center',
                                                                                alignItems: 'center',
                                                                                margin: '5px',
                                                                                cursor: 'pointer',
                                                                                border: (color?.is_primary) ? '2px solid black' : 'none',
                                                                            }}
                                                                            title={`${color?.colors?.color_name}`}
                                                                            onMouseOver={(e) => handleColorHover(e, color?.image_index, product?.product_id, color.product_image, product?.base_url)}
                                                                            onMouseOut={(e) => handleColorHover(e, product?.primary_image_index, product?.product_id, product?.primary_color_image_data, product?.base_url, 'out', product.product_color_images)}
                                                                            onClick={(e) => handleAddToFavorite(e, color?.image_index, product?.product_id, color.product_image, product?.base_url, color.id)}
                                                                        ></div>
                                                                    )
                                                                })}
                                                            </div>

                                                            <div className="container-desp">
                                                                <div className="text-left"><b> <a href="javascript:void(0);">{product?.brand_name}</a> </b></div>
                                                                <div className="text-left">
                                                                    <a className="ml" target="_blank" href={product?.product_url}>
                                                                        <small> {product?.product_name} </small>
                                                                    </a>
                                                                </div>
                                                                <div>
                                                                    {(product?.price === product?.retail_price) ? <span className="pull-left"><small>${product?.price}</small></span> : <span className="pull-left"><strike>${product?.retail_price}</strike><small>${product?.price}</small></span>}
                                                                    <span className="pull-right">
                                                                        <a href="javascript:void(0);" id={"favIcon-link" + product?.product_id} onClick={(e) => handleWishModalShow(product?.product_id)}>
                                                                            <i className={`fa fa-heart${(isFavorite(product, product.product_id) && favProductUsers?.includes(auth?.userDetails?.id)) ? '' : '-o'} my_whish_btn`} aria-hidden="true" id=""></i>
                                                                        </a>
                                                                    </span>
                                                                </div>
                                                            </div>

                                                            {/* <!--  WhishList Modal--> */}
                                                            <div id={`myWishModal${product?.product_id}`} className="whis-modal" style={{ display: 'none' }}>
                                                                <div className="modal-content">
                                                                    <a href="javascript:void(0);" onClick={(e) => handleWishModalClose(product?.product_id)}>
                                                                        <span className="close">
                                                                            <svg fill="#c9c9cc9" className="close-icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" width="16px" height="16px"><path d="M 2.75 2.042969 L 2.042969 2.75 L 2.398438 3.101563 L 7.292969 8 L 2.042969 13.25 L 2.75 13.957031 L 8 8.707031 L 12.894531 13.605469 L 13.25 13.957031 L 13.957031 13.25 L 13.605469 12.894531 L 8.707031 8 L 13.957031 2.75 L 13.25 2.042969 L 8 7.292969 L 3.101563 2.398438 Z" /></svg>
                                                                        </span>
                                                                    </a>
                                                                    <p><b>COLOR: </b>
                                                                        <select id={`colorListing${product?.product_id}`} style={{width: '50%'}}>
                                                                            {product?.product_color_data.map((colors, index) => {
                                                                                return (
                                                                                    <option 
                                                                                        key={index} 
                                                                                        value={colors?.id} 
                                                                                        data-parent={colors?.colors?.id}
                                                                                        selected={Number(favColorId) === Number(colors?.id) ? 'selected' : ''}
                                                                                    >
                                                                                        {colors?.color_name}
                                                                                    </option>
                                                                                )
                                                                            })}
                                                                        </select>
                                                                    </p>
                                                                    {/* <p><b>SIZE:</b> <span className="sizes">xxs </span> <span className="sizes">xxs </span> <span className="sizes">m </span> <span className="sizes">l </span> <span className="sizes">s </span></p> */}
                                                                    <p className='action-button'>
                                                                        <a target="_blank" href={product?.product_url}>
                                                                            <span className="buynow">Buy Now</span>
                                                                        </a>
                                                                        <span className="addtocart" onClick={(e) => handleFavorite(product?.product_id, e)}>{favorite.wishContent} <i className="fa fa-heart" aria-hidden="true"></i></span>
                                                                    </p>
                                                                </div>
                                                            </div>
                                                            {/* <!--  WhishList Modal - Over--> */}
                                                        </div>
                                                    </div>
                                                </div>
                                            )
                                        }
                                    }) : ((list?.data?.length == 0 && loading !== true) && (
                                        <tr className="text-center no-record-found d-table m-auto"><td>Product not found</td></tr>

                                    ))}

                                </div>

                                {/* pagination */}
                                <div className="row col-md-12 text-center no-pad-380">
                                    {list?.total_pages > 1 && (
                                        <div className="">
                                            <div
                                                className="pagination bottom justify-content-center"
                                            >
                                                <ReactPaginate
                                                    forcePage={page}
                                                    previousLabel={"previous"}
                                                    nextLabel={"next"}
                                                    breakLabel={"..."}
                                                    breakClassName={"break-me"}
                                                    pageCount={list.total_pages}
                                                    marginPagesDisplayed={2}
                                                    pageRangeDisplayed={2}
                                                    onPageChange={handlePageChange}
                                                    containerClassName={"pagination pagination-sm"}
                                                    activeClassName={"active"}
                                                    pageLinkClassName={"page-link"}
                                                    pageClassName={"paginate_button page-item"}
                                                    previousClassName={"paginate_button page-item previous"}
                                                    previousLinkClassName={"page-link"}
                                                    nextClassName={"paginate_button page-item previous"}
                                                    nextLinkClassName={"page-link"}
                                                />
                                            </div>
                                        </div>
                                    )}

                                </div>
                            </div>

                        </div>
                    </div>
                </div>

                {/* Product details modal start */}
                <ProductModal
                    show={show}
                    handleClose={handleClose}
                    productId={productId}
                    productDetails={productDetails}
                    // favoritesData={favoritesData}
                    favorite={favorite}
                    // setFavorite={setFavorite}
                    handleFavorite={handleFavorite}
                />
                {/* Product details modal end */}

                {/* Wish modal start */}
                {/* <WishModal show={wishModalShow} handleClose={handleWishModalClose} /> */}
                {/* Wish modal end */}
            </section>


        </div>
    );
}

export default ProducstList;